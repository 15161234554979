@import '../../../styles.scss';

$font-size: 12px;
$font-color: #333;
$textHighlightColor :#00ADFF;
$buttonColor: #91A516;
$font-weight: inherit;
$line-height: inherit;
$letter-spacing: inherit;
$border-color: #e1e1e1;

@font-face{
    font-family: Myriad-Pro Light;
    src: url('/assets/templates/template_001/fonts/MyriadPro-Light.otf');
}

@font-face{
    font-family: Myriad-Pro Regular;
    src: url('/assets/templates/template_001/fonts/MyriadPro-Regular.otf');
}

@font-face{
    font-family: Myriad-Pro Bold;
    src: url('/assets/templates/template_001/fonts/MyriadPro-Bold.otf');
}

@font-face {
    font-family: ZapfinoForteLTPro;
    src: url('/assets/templates/template_001/fonts/ZapfinoForteLTPro.otf');
}
.defaultWidth{
    width: 1024px;
    margin: 0 auto;
}
.txtFieldCls{
    background-color: #f6f6f6;
    border: 1px solid #e1e1e1;
    color: #7a7a7a;
    font-size: 12px;
    height: 40px;
    margin:0;
    width: 70%!important;
    outline: medium none;
    padding: 0 10px;
    font-size: 12px;
}
.txtFieldCls:focus{
    box-shadow: 0 0 8px #e1e1e1;
    background-color: white;
    // border: 1px solid $buttonColor;
}
#suggestion-container {
    height: 100%;
    width:100%;
    border-bottom: medium none !important;
}
.search-panel i{
    display: none;
}
input:focus {
    outline: medium none !important;
}
#suggestion-container input{
    padding-left: 10px;
    margin: 0;
    // margin-top: 7px;
    // height: 26px;
    border-radius: 3px;
    height:100%;
    background-color: white;
    font-size: 14px;
    color: #7a7a7a;
    width: calc(100% - 10px);
}
::placeholder{
    color:#7A7A7A !important;
    font-size: 12px;
}
#suggestion-container input::-webkit-input-placeholder{
    color: #7a7a7a;
    font-size: 12px;
}
.goPnl {
    float: right;
    width: 50px;
    font-size: 14px;
    height: 100%;
    background-color: #000000 !important;
    color: white;
    cursor: pointer;
}
.blog-content-panel .content img {
    display: none ;
}
.search-panel app-search > div {
    height: 100%;
    width: 100%;
}
button:focus, a:focus {
    outline: medium none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
perfect-scrollbar{
    margin-bottom: 20px !important;
    max-height: 240px !important;
    overflow: auto !important;
    height: auto !important;
}
.typeSpecFilterPanel perfect-scrollbar{
    overflow: auto !important;
    max-height: 145px !important;
}
#options-overlay {
    width:100% !important;
    max-height: 220px !important;
    top: -1px !important;
    left: 0px !important;
    position: relative !important;
}
#options-overlay a {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
    display: inline-block;
    width: 100%;
}
.waves-effect.option div {
    padding: 7px;
}
.loginPopUp .login-component{
    height: 400px;
    width: 770px;
    max-width: 770px !important;
    margin-bottom: 0 !important;
}
.loginPopUp .heading-lbl{
    visibility: visible !important;
}
// .loginPopUp .login-component{
//     border: 6px solid $textHighlightColor;
// }
body{
    min-height: 100%;
    height: auto !important;
    font-family: Verdaana, sans-serif;
}
.mat-dialog-container{
    border: medium none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 0 !important;
}
.hide{
    display: none !important;
    opacity: 0;
}
.rating-container{
    padding: 0px !important;
}
ls-suggestion{
    width: calc(100% - 90px) !important;
    display: inline-block;
    height: 100%;
}
a {
    color: black;
    text-decoration: none;
    outline: medium none;
}
.static-page p {
    color: #005825;
    font-family: Myriad-Pro Regular;
    line-height: 1.9;
    font-size: 14px;
}
input.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
textarea.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
select.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
.master{
    height: auto !important;
    position: relative;
}
.file-snip p{
    font-family: Myriad-Pro Regular;
    color: #005825;
    font-size: 14px;
}
.ps__rail-y {
    background-color: #e8e8e8 !important;
    width: 2px !important;
}

.ps__thumb-y {
    width: 4px !important;
    right: 0px !important;
    background-color: #aaa!important;
}
.static-page *{

}
select:focus{
    outline: none;
}
.catHeadingWrapper{
    height: 35px;
    width: 100%;
    display: inline-block;
    .catHeadingContainer{
        width:92%;
        float: left;
        display: table;
        background-color: #1F1D1C;
        border-left: 6px solid #F0C311;
        height: 100%;
        padding: 0 10px;
        .catHeading{
            width: auto;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
            // text-align: center;
            margin: 0;
            // padding: 15px 7px 7px;
            text-transform: uppercase;
        }
    }
}
.spinner{
    @include container(30px, 50px);
    font-size: 12px;
    .firstDiv{
        width: 30px;
        background-color: $textHighlightColor;
        input{
            @include container(100%, 100%);
            padding: 0px 10px;
            font-size: 12px;
            color: #7a7a7a;
            background-color: #f6f6f6;
            border: 1px solid #e1e1e1;
        }
    }
    .secondDiv{
        width: 20px;
        color: white;
        cursor: pointer;
        background-color: $textHighlightColor;
        .prodQuantitySpinnerUp{
            margin-top: 2px;
        }
        .prodQuantitySpinnerDown{
            margin-bottom: 2px;
        }
    }
}
.disable{
    opacity: 0.6;
    pointer-events: none;
}
.content-center{
    display: table-cell;
    vertical-align: middle;
}
@media screen and (min-width:1024px){
    .panelClass {
        position: absolute !important;
        // right: 14% !important;
        right: 30% !important;
        // top: 45px;
        top:60px;
    }
}
@media screen and (max-width: 1024px){
    ls-suggestion{
        width: 95% !important;
    }
    .search-panel app-search > div{
        background-color: transparent;
    }
    app-search{
        width: 100%;
    }
    #options-overlay{
        width: 80% !important;
    }
    .menu-width {
        width: 70% !important;
        height: 100vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass {
        position: absolute !important;
        right: 20% !important;
        top: 130px;
    }
    .static-page *{
       max-width: 100%;
       display: inline-block;
    }
}

@media screen and (max-width:767px){
    #options-overlay {
        width: 75% !important;
    }
    .static-page * {
        max-width: 100%;
        // width: 100%;
        line-height: 1.2;
        display: inline-block;
        font-size: 1em !important;
    }
    .static-page img {
        width: 100% !important;
        height: auto;
    }
    #suggestion-container input,span#lnkGo{
        color: #a0a0a0 !important;
    }
    .cdk-global-overlay-wrapper{
        background-color: rgba(0,0,0,0.7) !important;
    }
    app-order-tracking {
        padding: 0 20px;
        display: inline-block;
        width: 100%;
    }
    .mce-panel {
        width: 100% !important;
    }
    ._2p3a{
        min-width: 100% !important;
    }
    .static-page p{
        line-height: 1.5;
    }
    .typeSpecFilterPanel perfect-scrollbar{
        margin-bottom: 0 !important
    }
    .menu-width {
        width: 80% !important;
        height: 78vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass{
        position: static !important;
    }
}
@media screen and (min-width :1024px) and (max-width:1280px){
    .panelClass{
        right: 20% !important;
    }
}